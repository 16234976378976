.contact {
	padding: 50px 0 30px 0;
    background: linear-gradient(to right, #1d2e17 0%, #203519 100%);
	color: rgba(255,255,255,.75);
}

.contact .section-title p {
	font-size: 16px;
}
.contact h2 {
	color: #fff;
	margin-top: 10px;
	margin-bottom: 20px;
    padding-bottom: 15px;
    text-align: left;
}
.contact .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(255,255,255,.3);
	height: 4px;
	width: 150px;
	bottom: 0;
	left: 30px;
}
.contact h3 {
	color: #fff;
	margin-top: 80px;
	margin-bottom: 25px;
	padding-bottom: 20px;
	font-weight: 400;
}
.contact form {
	padding-top: 20px;
}
.contact .text-danger {
	color: #cc0033;
	text-align: left;
}
.contact .btn-custom {
	margin: 10px 0;
	background: transparent;
	border: 2px solid #fff;
}
.contact .btn-custom:hover {
	color: #1f386e;
	background: #fff;
}
label {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	float: left;
}
.contact .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0.1px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
.contact .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
color: #777;
}
.form-control:-moz-placeholder {
color: #777;
}
.form-control::-moz-placeholder {
color: #777;
}
.form-control:-ms-input-placeholder {
color: #777;
}

.contact .contact-item {
	margin: 20px 0;
}
.contact .contact-item span {
	color: rgba(255,255,255,1);
	margin-bottom: 10px;
	display: block;
}
.contact .contact-item i.fa {
	margin-right: 10px;
}

