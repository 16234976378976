.products {
  padding: 60px 0;
  background: linear-gradient(to right, #1d2e17 0%, #203519 100%);
  color: #fff;
  text-align: center;
  justify-content: center;
}
.products img {
  width: 220px;
  margin: 20px 0;
  border: solid 3px #f58220;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  height: 150px;
}
.dispblock {
  display: block !important;
}
.section-title {
  margin-bottom: 10px;
}
.section-title h2 {
  position: relative;
  color: #FFFBD6;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-weight: 800;
  font-size: 26px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
    color: #FFFBD6;
  font-size: 14px;
}
h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #FFFBD6;
  font-size: 20px;
}
.hover-text{
  display: none;
}
.hover-bg {
   
    position: relative;
    margin: 0;
}
.hover-bg .hover-text {
    position: absolute;
    text-align: center;
    color: #fff;
    background: linear-gradient(to right, rgba(250, 143, 49, 0.6) 0%, rgba(245,130,32,0.6) 100%);
    padding: 30% 0 0 0;
    opacity: 0.1;
    transition: all 0.5s;
}
.hover-bg .hover-text>h4 {
    opacity: 0;
    color: #fff;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: all 0.3s;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
}
.hover-bg:hover .hover-text>h4 {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.hover-bg:hover .hover-text {
	opacity: 1;
  display: block;
  margin-top: -1px;
  margin-left: -1px;
}
@media (max-width: 767px){
    .hover-text {
        display: none;
    }
}
@media (min-width: 768px){
    .products img {
        width: 220px;
        height: 150px
    }
    .hover-text {
        width: 220px;
        height: 150px;
        margin: 20px 0;
        
    }
    
}
@media (min-width: 992px) {
   
    .section-title h2 {
        font-size: 36px;
    }
    .section-title p {
        font-size: 18px;
    }
    .products img {
       width: 280px;
       height: 183px;
       border: solid 5px #f58220;
    }
    .hover-text {
        width: 280px;
        height: 183px;
        margin-left: 4px;
    }
}
@media (min-width: 1200px){
    .products img {
        width: 350px;
        height: 230px;
     }
     .hover-text {
        width: 350px;
        height: 230px;
        margin-left: 0;
    }
}