.container {
  /* width: 400px; */
}
.flyer{
  text-align: center;
}
.intro {
  background: url(../../assets/headerbackground.jpg) top center no-repeat;
}

.overlay {
  background: rgba(0, 0, 0, 0.3);
}
header .intro-text {
  padding-top: 140px;
  padding-bottom: 100px;
  text-align: center;
}
.header-logo {
  height: 150px;
  width: 200px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.intro h1 {
  color: #FFFBD6;
  font-size: 38px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}
.intro p {
  color: #FFFBD6;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  margin: 0 auto;
  margin-bottom: 0px;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
.btn-custom {
  font-family: "Montserrat", sans-serif !important;
  text-transform: uppercase;
  color: #fff;
  background-color: #f58220;
  /* background-image: linear-gradient(to right, #F58220 0%, #1D2E17  100%); */
  padding: 14px 34px;
  letter-spacing: 1px;
  margin-top: 15px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  border: 0;
  transition: transform 0.2s ease;
}
.btn-custom a {
  color: #ffffff;
  font-size: 18px;
}
.btn-custom a:hover {
  color: #ffffff;
}
.btn-custom:hover {
  color: #fff;
  transform: translateY(-2.2px);
}
.btn-custom:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.btn-custom:focus {
  outline: none;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-offset-2 {
    margin-left: 14.66666667%;
  }
  .intro h1 {
    font-size: 62px;
  }
  .intro p {
    font-size: 22px;
    margin-bottom: 20px;
    line-height: 30px;
  }
  .header-logo {
    height: 250px;
    width: 330px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .col-md-offset-2 {
    margin-left: 28.66666667%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1180px;
  }
  .col-md-offset-2 {
    margin-left: 34.66666667%;
  }
}
@media (min-width: 1600px) {
  .intro {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
}