.showcase-text h2 {
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-weight: 800;
  font-size: 26px;
  color: #333;
}
body{
  background: #d3ebb8;
}
.showcase-text h3 {
  margin: 0 0 20px 0;
  font-weight: 800;
  font-size: 20px;
  color: #333;
}

.showcase img {
  margin: 50px 0;
  width: fit-content;
  max-height: 600px;
  border: double 10px #f58220;
  border-radius: 10px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
.footer img {
  border: double 10px #f58220;
  border-radius: 10px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
.showcase-text {
  align-items: center;
  justify-content: center;
}
.showcase-text p {
  font-size: 14px;
}

.showcase {
  position: relative;
  padding: 25px 0;
  text-align: left;
  background-color: #d3ebb8;
}
.split-item {
  padding-top: 40px;
  padding-bottom: 40px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
#donate-button {
  margin: auto;
  border: none;
  height: 50px;
  width: 150px;
  cursor: pointer;
  margin-bottom: 80px;
}

@media (min-width: 768px) {
  .showcase img {
    width: 340px;
    margin-left: -20px;
  }
  .order {
    order: 1;
  }
}
@media (min-width: 992px) {
  .showcase {
    padding: 50px 0;
  }
  .showcase-text h2 {
    font-size: 36px;
    margin-top: 55px;
  }
  .showcase-text p {
    font-size: 18px;
  }
  .col-md-6 {
    width: 50%;
  }
  .showcase img {
    margin: 100px 0;
    margin-left: -10px;
    width: 450px;
  }
}
@media (min-width: 1200px) {
  .showcase img {
    width: 540px;
    margin-left: -20px;
  }
}

ul {
  list-style-type: square;
}
