* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

  body {
    font-family: "Montserrat", sans-serif !important;
  }
  
  .navbar {
    font-size: 18px;
    background-image: linear-gradient(260deg, #F58220 0%, #1D2E17 100%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 5px;
    display: block;
    margin-left: -0.8px;
    width: 100.5%;
    position: fixed;
    z-index: 1;
  }
  .container{
    display: block !important;
  }
  
  .main-nav {
    list-style-type: none;
    display: none;
  }
  
  .nav-links,
  .logo {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .main-nav li {
    text-align: center;
    margin: 15px auto;
  } 
  .logo {
    display: flex;
    font-size: 22px;
    margin-top: 10px;
    /* margin-left: 20px; */
    padding-bottom: 10px;
    text-align: left;
  }
  
  .navbar-toggle {
    position: absolute;
    top: 30px;
    right: 9%;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
  }
  
  .active {
    display: block !important ;
    
  }
  
  @media screen and (min-width: 768px) {
    .navbar {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
      height: 70px;
      align-items: center;
    }
    .container {
      display: flex !important;
    }
  
    .main-nav {
      display: flex !important;
      margin-right: 30px;
      flex-direction: row;
      justify-content: flex-end;
    }
  
    .main-nav li {
      margin: 0;
    }
  
    .nav-links {
      margin-left: 40px;
    }
  
    .logo {
      margin-top: 0;
    }
  
    .navbar-toggle {
      display: none;
    }
  
    .logo:hover,
    .nav-links:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  